import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
import base, { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModaalComponent } from './modaal/modaal.component';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {
  delAddress: any;
  currentPage: number;
  vendorList: any;
  totalItems: any;
  serialNumber = 0;
  loading: boolean = true;
  color: ThemePalette = 'primary';
  imageUrl = base;
  defaultImage = "assets/img/defaultuser.jpg";
  search: any = ''
  userType: any = "";
  role: any;
  access: any;

  constructor(private api: ApiService, private toaster: ToastrService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
    this.role = JSON.parse(localStorage.getItem('Dayfresh_Admin'));
    this.access = JSON.parse(localStorage.getItem('permission'));
    if (this.role.type == 'Admin') this.role.fence = '';
    this.currentPage = 0;
    this.getallVedors()
  }

  getallVedors() {
    console.log(this.search,this.role.fence,"-----")

    if(this.role.fence){
      
    }else{
      this.role.fence = '';
    }
    this.api.getVendor(this.currentPage, this.role.fence, this.search).subscribe((response: any) => {
      this.vendorList = response.data;
      this.totalItems = response.count
      this.loading = false;
      // console.log(this.totalItems);
      // console.log(this.vendorList);
    })
  }

  verifyVendor(data) {
    if (data.fence) {
      let body = {
        id: data._id,
        isVerify: true
      }
      this.api.updateVendor(body).subscribe((response: any) => {
        if (!response.success) {
          return this.errorToast(response.message);
        }
        this.successToast('Vendor verified successfully!');
        this.currentPage = this.currentPage - 1;
        this.getallVedors();
        this.currentPage = this.currentPage + 1;
      })
    } else {
      this.toaster.error("Please select the geofence");
    }

  }

  approveVendor(data) {
    let body = {
      id: data._id,
      isApproved: true
    }
    this.api.updateVendor(body).subscribe((response: any) => {
      if (!response.success) {
        return this.errorToast(response.message);
      }
      this.successToast('Vendor approved successfully!');
      this.getallVedors();
    })
  }

  //delete User
  deleteVendor(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this Vendor!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.api.deleteVendor(id).subscribe((res: any) => {
          if (!res.success) return;
          Swal.fire({
            title: 'Deleted Successfully',
            text: 'Vendor has been deleted!',
            icon: 'success'
          })
          this.getallVedors();
        })
      }
    })
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(ModaalComponent, {
      width: '700px',
      data : id
      // data: {name: "yfhj", animal: "sdz"}
    });
  }

  onPageChange(pages) {
    this.currentPage = pages.page - 1;
    this.serialNumber = 10 * (this.currentPage + 1) - 10;
    this.getallVedors()
  }

  successToast(message) {
    this.toaster.success(message)
  }

  errorToast(message) {
    this.toaster.error(message);
  }

  searchVendor() {
    this.currentPage = 0;
    this.getallVedors();
  }

  resetVendor() {
    this.search = '';
    this.currentPage = 0;
    this.getallVedors();
  }

}


