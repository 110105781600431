import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
// import * as fileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import base from '../../services/api.service';
import { ThemePalette } from '@angular/material/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ModallllComponent} from './modallll/modallll.component'

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit {
  url = base;
  totalItems: number; 
  selectedAddress: any;
  selectedDocument: any;
  currentPage = 1;
  serialNumber = 0;
  driverList: any = [];
  selectDriver: any = ''
  loading: boolean = true;
  color: ThemePalette = 'primary';
  role: any;
  access: any;
  truckList: any;

  constructor(
    private api: ApiService, private toaster: ToastrService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.role = JSON.parse(localStorage.getItem('Dayfresh_Admin'));
    this.access = JSON.parse(localStorage.getItem('permission'));
    this.getDrivers();
    this.getVehicles();
  }
  getVehicles(){
    this.api.getVehicle().subscribe((res:any)=>{
      this.truckList = res.data;
      this.loading = false;
    })
  }

  getDrivers() {
    this.api.getDrivers(this.currentPage, this.selectDriver).subscribe((response: any) => {
      if (!response.success) return;
      this.driverList = response.data;
      this.loading = false;
      for (let i = 0; i < this.driverList.length; i++) {
        if (this.driverList[i].type == 'B2C') {
          this.driverList[i].type = 'Dayfresh';
        }
        if (this.driverList[i].type == "") {
          this.driverList[i].type = '';
        }
      }
      this.totalItems = response.count;
    }, error => {
    });
  }
  onchange(veh,dri){
    const body ={
      vehicleId : veh ,
      driverId : dri
    }
    this.api.assignVehicle(body).subscribe((res : any)=>{
      if(res.success){
        this.successToast("Vehicle Assigned");
        this.getDrivers();
      }
    })
  }

  selectDriverType(type, id) {
    const formData = new FormData();
    if (type == "") return this.errorToast('Please select valid Type')
    if (type == "Dayfresh") {
      formData.append('type', 'B2C')
      formData.append('appCountry', JSON.stringify(['india']))
    }
    if (type == 'Taazij') {
      formData.append('type', 'B2C')
      formData.append('appCountry', JSON.stringify(['kuwait']))
    }
    if (type == "B2B") {
      formData.append('type', 'B2B')
    }
    formData.append('id', id)

    this.api.editDriver(formData).subscribe((response: any) => {
      if (!response.success) {
        return this.errorToast(response.message);
      } else {
        this.successToast('Driver Edit successfully!');
        this.getDrivers();
      }
    }, error => {
      // this.flags.isUpdate = false;
    });
  }

  searchDriver() {
    this.getDrivers();
  }

  resetDriver() {
    this.selectDriver = '';
    this.currentPage = 1;
    this.getDrivers();
  }


  getAddress(data) {
    this.selectedAddress = {
      address: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      street: data.street,
      pinCode: data.pinCode
    }
  }

  getDocuments(docs) {
    this.selectedDocument = {
      documentOne: docs.documentOne,
      documentTwo: docs.documentTwo,
      documentThree: docs.documentThree
    }
  }

  generateDriverCsv() {
    // saveAs('https://api.tarbal.com/api/admin/generateDriverCSV', "Driver.xlsx")
    saveAs('https://demo.appdukaan.com:9045/api/admin/generateDriverCSV', "Driver.xlsx")
  }

  //delete pack
  deleteDriver(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this driver!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.api.deleteDriver(id).subscribe((res: any) => {
          if (!res.success) return;
          Swal.fire({
            title: 'Deleted Successfully',
            text: 'Driver has been deleted!',
            icon: 'success'
          })
          this.getDrivers();
        })
      }
    })
  }

  approveDriver(item: any, type) {
    if (item.driverType == '') return this.errorToast('Select Type First')
    const formData = new FormData();
    formData.append('id', item._id);
    if(type== 2){
      formData.append('profileStatus', '1');
    }else{
      formData.append('profileStatus', item.profileStatus);
    }
    if (type == 1) formData.append('isApprove', 'true');
    if (type == 2) formData.append('isVerified', 'true');
    this.api.editDriver(formData).subscribe((response: any) => {
      if (!response.success) return this.errorToast(response.message);
      this.successToast('Driver updated successfully!');
      this.getDrivers();
    });
  }

  changeStatus(event, item) {
    const formData = new FormData();
    formData.append('id', item._id);
    formData.append('isActive', event)
    this.api.editDriver(formData).subscribe((response: any) => {
      if (!response.success) return this.errorToast(response.message);
      this.successToast('Driver updated successfully!');
      this.getDrivers();
    });
  }
  openDialog(id): void {
    const dialogRef = this.dialog.open(ModallllComponent, {
      width: '700px',
      data : id
      // data: {name: "yfhj", animal: "sdz"}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDrivers();
      
    //   console.log('The dialog was closed', result);
      // if(result === 'yes'){
      //   this.currentPage = 0;
      //   this.getTransactionOrders();
      // }
    //   // this.animal = result;
    });
  }


  onPageChange(pages) {
    this.currentPage = pages.page;
    this.serialNumber = 10 * this.currentPage - 10
    this.getDrivers();
  }

  successToast(message) {
    this.toaster.success(message)
  }

  errorToast(message) {
    this.toaster.error(message);
  }

}
