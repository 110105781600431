import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/commonservice/auth.guard';
import { LoggedGuard } from 'src/app/commonservice/logged.guard';

import { StaticComponent } from './static/static.component';
// import { AuthguardGuardGuard } from './commonservice/authguard-guard.guard';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login', 
    pathMatch: 'full'
  },
  // {
  //   path: 'b2b',
  //   canActivate: [AuthguardGuardGuard],
  //   loadChildren: () => import('./b2b/b2b.module').then(m => m.B2bModule)
  // },
  {
    path: 'b2c',
    canActivate: [AuthGuard],
    loadChildren: () => import('./b2c/b2c.module').then(m => m.B2cModule)
  },

  {
    path: 'login',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'sign-up',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./vendor-signup/vendor-signup.module').then(m => m.VendorSignupModule)
  },
  {
    path: 'verification/:token',
    component: EmailVerificationComponent
  },
  {
    path : 'reset-password/:id',
    component : ResetPasswordComponent
  },
  {
    path : 'tcs',
    component : StaticComponent
  },
  {
    path : 'privacyPolicy',
    component : PrivacyComponent
  },
  {
    path : 'about',
    component : AboutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true,
    scrollPositionRestoration: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
